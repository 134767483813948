import React from 'react';
import styled from '@emotion/styled';
import FunctionsHeader from 'components/Organisms/PageHeader/FunctionsHeader';
import ContactDownload from 'components/Organisms/ContactDownload';
import { FluidObject } from 'gatsby-image';
import Navigation from 'components/Organisms/Navigation';
import WhyChooseVoda from 'components/Organisms/WhyChooseVoda';
import Footer from 'components/Organisms/Footer';
import PageMainDesc from 'components/Organisms/PageMainDesc';
import MoreButton from 'components/Molecule/MoreButton';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding-left: 60px;
  padding-bottom: 60px;
`;

interface ContactDownloadTemplateProps {
  logoNerdFactoryImg: FluidObject;
  img: FluidObject;
  location: {
    pathname: string;
  };
}

const ContactDownloadTemplate = ({
  logoNerdFactoryImg,
  img,
  location,
}: ContactDownloadTemplateProps) => {
  return (
    <Component>
      <Navigation vodaLogoImg={logoNerdFactoryImg} />
      <FunctionsHeader
        title="VODA 쉽게 사용할 수 있도록\n안내자료를 드립니다."
        img={img}
      />
      <PageMainDesc
        desc={{
          // main: '당신을 위한 VODA 가이드\\n다운로드 받아서 활용해보세요.',
          main: '당신을 위한 VODA 가이드\\n다운로드 요청하기',
          sub: '',
        }}
        location={location}
      />
      {/* <ContactDownload /> */}
      <ButtonWrapper>
        <MoreButton to="/contact/form" title="다운로드 요청하기" />
      </ButtonWrapper>
      <WhyChooseVoda />
      <Footer />
    </Component>
  );
};

export default ContactDownloadTemplate;
