import React from 'react';
import styled from '@emotion/styled';
import { ContainerGrid, ItemGrid } from 'components/Atom/DefualtLayout';
import { H1, H4, P1, P3 } from 'components/Atom/Typo';
import Breadcrumb from '../Breadcrumb';

const Component = styled.div`
  width: 100%;
  height: 374px;
  display: flex;
  justify-content: center;
`;

const MaxWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  padding: 80px 60px;
`;

const DescWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;

  ${H1} {
    font-size: 40px;
    line-height: 1.4;
  }

  ${P1} {
    color: ${(props) => props.theme.colors.grey600};
    line-height: 1.75;
  }
`;

interface PageMainDescProps {
  className?: string;
  desc: {
    sub: string;
    main: string;
    long?: string;
  };
  location: {
    pathname: string;
  };
}

const PageMainDesc = ({ desc, className, location }: PageMainDescProps) => {
  return (
    <Component className={className}>
      <MaxWrapper>
        <ContainerGrid spacing={'xsm'}>
          <ItemGrid desktop={6}>
            <DescWrapper>
              <Breadcrumb location={location} />
              {desc.main.split('\\n').map((element) => {
                return <H1 key={element}>{element}</H1>;
              })}
            </DescWrapper>
          </ItemGrid>
          <ItemGrid desktop={6}>
            <DescWrapper>
              {desc.long &&
                desc.long.split('\\n').map((element) => {
                  return <P1 key={element}>{element}</P1>;
                })}
            </DescWrapper>
          </ItemGrid>
        </ContainerGrid>
      </MaxWrapper>
    </Component>
  );
};

export default PageMainDesc;
