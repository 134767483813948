import React from 'react';
import styled from '@emotion/styled';
import ContactDownloadTemplate from 'components/Templates/ContactDownloadTemplate';
import { graphql, withPrefix } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface DownloadProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const Download = ({ data, location }: DownloadProps) => {
  console.log('location: ', location);
  const title = 'VODA | 문의 | 자료실';
  const desc = 'VODA 자료실';
  return (
    <Component>
      <Helmet>
        <title>VODA | 문의 | 자료실</title>
        <meta name="description" content="VODA 자료실" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/contact/download>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <ContactDownloadTemplate
        location={location}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
        img={data.topFunctionImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default Download;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-archives@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
